//
// Typography
//

$Franklin-100: 'LibreFranklin-100';
// $Franklin-200: 'LibreFranklin-200';
// $Franklin-300: 'LibreFranklin-300';
$Franklin-400: 'LibreFranklin-400';
$Franklin-500: 'LibreFranklin-500';
$Franklin-600: 'LibreFranklin-600';
$Franklin-700: 'LibreFranklin-700';

@font-face {
	font-family: $Franklin-100;
	src: url('../../../fonts/LibreFranklin/LibreFranklin-Light.ttf');
}
@font-face {
	font-family: $Franklin-400;
	src: url('../../../fonts/LibreFranklin/LibreFranklin-Regular.ttf');
}
@font-face {
	font-family: $Franklin-500;
	src: url('../../../fonts/LibreFranklin/LibreFranklin-Medium.ttf');
}
@font-face {
	font-family: $Franklin-600;
	src: url('../../../fonts/LibreFranklin/LibreFranklin-SemiBold.ttf');
}
@font-face {
	font-family: $Franklin-700;
	src: url('../../../fonts/LibreFranklin/LibreFranklin-Bold.ttf');
}
//Regular 400 Medium 500 SemiBold 600 bold700
