/*
Breakpoints
==========================================================================
*/
/*
NEW COLORS
*/
.fe-color-new-base-black {
  color: #000000;
}
.fe-color-new-base-white {
  color: #ffffff;
}
.fe-color-new-grey-600 {
  color: #222222;
}
.fe-color-new-grey-500 {
  color: #3e3d40;
}
.fe-color-new-grey-400 {
  color: #5e5e5e;
}
.fe-color-new-grey-300 {
  color: #98989a;
}
.fe-color-new-grey-200 {
  color: #ececed;
}
.fe-color-new-grey-100 {
  color: #f8f8f9;
}
.fe-color-new-success-100 {
  color: #eef7f0;
}
.fe-color-new-success-200 {
  color: #46905b;
}
.fe-color-new-error-100 {
  color: #ffeded;
}
.fe-color-new-error-200 {
  color: #b90608;
}
.fe-color-new-warn {
  color: #eebe5e;
}
.fe-color-new-info {
  color: #6177b9;
}
.fe-color-primary-50 {
  color: #f3f6fb;
}
.fe-color-primary-100 {
  color: #eff4ff;
}
.fe-color-primary-200 {
  color: #bed3ff;
}
.fe-color-primary-300 {
  color: #91b7ff;
}
.fe-color-primary-400 {
  color: #5d90fd;
}
.fe-color-primary-500 {
  color: #3264fa;
}
.fe-color-primary-600 {
  color: #3264fa;
}
.fe-color-primary-700 {
  color: #1933dc;
}
.fe-color-primary-800 {
  color: #1b2ab2;
}
.fe-color-primary-900 {
  color: #161c55;
}
.fe-color-primary-950 {
  color: #262f45;
}
.fe-color-secondary-50 {
  color: #f4f7fa;
}
.fe-color-secondary-100 {
  color: #e7ebf2;
}
.fe-color-secondary-200 {
  color: #d4dce9;
}
.fe-color-secondary-300 {
  color: #b6c5da;
}
.fe-color-secondary-400 {
  color: #93a7c7;
}
.fe-color-secondary-500 {
  color: #798db8;
}
.fe-color-secondary-600 {
  color: #6b7bab;
}
.fe-color-secondary-700 {
  color: #5b689a;
}
.fe-color-secondary-800 {
  color: #4e577f;
}
.fe-color-secondary-900 {
  color: #424966;
}
.fe-color-neutral-50 {
  color: #fcfdfe;
}
.fe-color-neutral-100 {
  color: #f1f5f9;
}
.fe-color-neutral-200 {
  color: #e2e8f0;
}
.fe-color-neutral-300 {
  color: #cbd5e1;
}
.fe-color-neutral-400 {
  color: #94a3b8;
}
.fe-color-neutral-500 {
  color: #64748b;
}
.fe-color-neutral-600 {
  color: #475569;
}
.fe-color-neutral-700 {
  color: #334155;
}
.fe-color-neutral-800 {
  color: #1e293b;
}
.fe-color-neutral-900 {
  color: #0f172a;
}

.fe-background-color-new-base-black {
  background-color: #000000;
}
.fe-background-color-new-base-white {
  background-color: #ffffff;
}
.fe-background-color-new-grey-600 {
  background-color: #222222;
}
.fe-background-color-new-grey-500 {
  background-color: #3e3d40;
}
.fe-background-color-new-grey-400 {
  background-color: #5e5e5e;
}
.fe-background-color-new-grey-300 {
  background-color: #98989a;
}
.fe-background-color-new-grey-200 {
  background-color: #ececed;
}
.fe-background-color-new-grey-100 {
  background-color: #f8f8f9;
}
.fe-background-color-new-success-100 {
  background-color: #eef7f0;
}
.fe-background-color-new-success-200 {
  background-color: #46905b;
}
.fe-background-color-new-error-100 {
  background-color: #ffeded;
}
.fe-background-color-new-error-200 {
  background-color: #b90608;
}
.fe-background-color-new-warn {
  background-color: #eebe5e;
}
.fe-background-color-new-info {
  background-color: #6177b9;
}
.fe-background-color-primary-50 {
  background-color: #f3f6fb;
}
.fe-background-color-primary-100 {
  background-color: #eff4ff;
}
.fe-background-color-primary-200 {
  background-color: #bed3ff;
}
.fe-background-color-primary-300 {
  background-color: #91b7ff;
}
.fe-background-color-primary-400 {
  background-color: #5d90fd;
}
.fe-background-color-primary-500 {
  background-color: #3264fa;
}
.fe-background-color-primary-600 {
  background-color: #3264fa;
}
.fe-background-color-primary-700 {
  background-color: #1933dc;
}
.fe-background-color-primary-800 {
  background-color: #1b2ab2;
}
.fe-background-color-primary-900 {
  background-color: #161c55;
}
.fe-background-color-primary-950 {
  background-color: #262f45;
}
.fe-background-color-secondary-50 {
  background-color: #f4f7fa;
}
.fe-background-color-secondary-100 {
  background-color: #e7ebf2;
}
.fe-background-color-secondary-200 {
  background-color: #d4dce9;
}
.fe-background-color-secondary-300 {
  background-color: #b6c5da;
}
.fe-background-color-secondary-400 {
  background-color: #93a7c7;
}
.fe-background-color-secondary-500 {
  background-color: #798db8;
}
.fe-background-color-secondary-600 {
  background-color: #6b7bab;
}
.fe-background-color-secondary-700 {
  background-color: #5b689a;
}
.fe-background-color-secondary-800 {
  background-color: #4e577f;
}
.fe-background-color-secondary-900 {
  background-color: #424966;
}
.fe-background-color-neutral-50 {
  background-color: #fcfdfe;
}
.fe-background-color-neutral-100 {
  background-color: #f1f5f9;
}
.fe-background-color-neutral-200 {
  background-color: #e2e8f0;
}
.fe-background-color-neutral-300 {
  background-color: #cbd5e1;
}
.fe-background-color-neutral-400 {
  background-color: #94a3b8;
}
.fe-background-color-neutral-500 {
  background-color: #64748b;
}
.fe-background-color-neutral-600 {
  background-color: #475569;
}
.fe-background-color-neutral-700 {
  background-color: #334155;
}
.fe-background-color-neutral-800 {
  background-color: #1e293b;
}
.fe-background-color-neutral-900 {
  background-color: #0f172a;
}

.fe-background-hover-color-new-base-black:hover {
  background-color: #000000;
}
.fe-background-hover-color-new-base-white:hover {
  background-color: #ffffff;
}
.fe-background-hover-color-new-grey-600:hover {
  background-color: #222222;
}
.fe-background-hover-color-new-grey-500:hover {
  background-color: #3e3d40;
}
.fe-background-hover-color-new-grey-400:hover {
  background-color: #5e5e5e;
}
.fe-background-hover-color-new-grey-300:hover {
  background-color: #98989a;
}
.fe-background-hover-color-new-grey-200:hover {
  background-color: #ececed;
}
.fe-background-hover-color-new-grey-100:hover {
  background-color: #f8f8f9;
}
.fe-background-hover-color-new-success-100:hover {
  background-color: #eef7f0;
}
.fe-background-hover-color-new-success-200:hover {
  background-color: #46905b;
}
.fe-background-hover-color-new-error-100:hover {
  background-color: #ffeded;
}
.fe-background-hover-color-new-error-200:hover {
  background-color: #b90608;
}
.fe-background-hover-color-new-warn:hover {
  background-color: #eebe5e;
}
.fe-background-hover-color-new-info:hover {
  background-color: #6177b9;
}
.fe-background-hover-color-primary-50:hover {
  background-color: #f3f6fb;
}
.fe-background-hover-color-primary-100:hover {
  background-color: #eff4ff;
}
.fe-background-hover-color-primary-200:hover {
  background-color: #bed3ff;
}
.fe-background-hover-color-primary-300:hover {
  background-color: #91b7ff;
}
.fe-background-hover-color-primary-400:hover {
  background-color: #5d90fd;
}
.fe-background-hover-color-primary-500:hover {
  background-color: #3264fa;
}
.fe-background-hover-color-primary-600:hover {
  background-color: #3264fa;
}
.fe-background-hover-color-primary-700:hover {
  background-color: #1933dc;
}
.fe-background-hover-color-primary-800:hover {
  background-color: #1b2ab2;
}
.fe-background-hover-color-primary-900:hover {
  background-color: #161c55;
}
.fe-background-hover-color-primary-950:hover {
  background-color: #262f45;
}
.fe-background-hover-color-secondary-50:hover {
  background-color: #f4f7fa;
}
.fe-background-hover-color-secondary-100:hover {
  background-color: #e7ebf2;
}
.fe-background-hover-color-secondary-200:hover {
  background-color: #d4dce9;
}
.fe-background-hover-color-secondary-300:hover {
  background-color: #b6c5da;
}
.fe-background-hover-color-secondary-400:hover {
  background-color: #93a7c7;
}
.fe-background-hover-color-secondary-500:hover {
  background-color: #798db8;
}
.fe-background-hover-color-secondary-600:hover {
  background-color: #6b7bab;
}
.fe-background-hover-color-secondary-700:hover {
  background-color: #5b689a;
}
.fe-background-hover-color-secondary-800:hover {
  background-color: #4e577f;
}
.fe-background-hover-color-secondary-900:hover {
  background-color: #424966;
}
.fe-background-hover-color-neutral-50:hover {
  background-color: #fcfdfe;
}
.fe-background-hover-color-neutral-100:hover {
  background-color: #f1f5f9;
}
.fe-background-hover-color-neutral-200:hover {
  background-color: #e2e8f0;
}
.fe-background-hover-color-neutral-300:hover {
  background-color: #cbd5e1;
}
.fe-background-hover-color-neutral-400:hover {
  background-color: #94a3b8;
}
.fe-background-hover-color-neutral-500:hover {
  background-color: #64748b;
}
.fe-background-hover-color-neutral-600:hover {
  background-color: #475569;
}
.fe-background-hover-color-neutral-700:hover {
  background-color: #334155;
}
.fe-background-hover-color-neutral-800:hover {
  background-color: #1e293b;
}
.fe-background-hover-color-neutral-900:hover {
  background-color: #0f172a;
}

.fe-border-color-new-base-black {
  border: 1px solid #000000;
}
.fe-border-color-new-base-white {
  border: 1px solid #ffffff;
}
.fe-border-color-new-grey-600 {
  border: 1px solid #222222;
}
.fe-border-color-new-grey-500 {
  border: 1px solid #3e3d40;
}
.fe-border-color-new-grey-400 {
  border: 1px solid #5e5e5e;
}
.fe-border-color-new-grey-300 {
  border: 1px solid #98989a;
}
.fe-border-color-new-grey-200 {
  border: 1px solid #ececed;
}
.fe-border-color-new-grey-100 {
  border: 1px solid #f8f8f9;
}
.fe-border-color-new-success-100 {
  border: 1px solid #eef7f0;
}
.fe-border-color-new-success-200 {
  border: 1px solid #46905b;
}
.fe-border-color-new-error-100 {
  border: 1px solid #ffeded;
}
.fe-border-color-new-error-200 {
  border: 1px solid #b90608;
}
.fe-border-color-new-warn {
  border: 1px solid #eebe5e;
}
.fe-border-color-new-info {
  border: 1px solid #6177b9;
}
.fe-border-color-primary-50 {
  border: 1px solid #f3f6fb;
}
.fe-border-color-primary-100 {
  border: 1px solid #eff4ff;
}
.fe-border-color-primary-200 {
  border: 1px solid #bed3ff;
}
.fe-border-color-primary-300 {
  border: 1px solid #91b7ff;
}
.fe-border-color-primary-400 {
  border: 1px solid #5d90fd;
}
.fe-border-color-primary-500 {
  border: 1px solid #3264fa;
}
.fe-border-color-primary-600 {
  border: 1px solid #3264fa;
}
.fe-border-color-primary-700 {
  border: 1px solid #1933dc;
}
.fe-border-color-primary-800 {
  border: 1px solid #1b2ab2;
}
.fe-border-color-primary-900 {
  border: 1px solid #161c55;
}
.fe-border-color-primary-950 {
  border: 1px solid #262f45;
}
.fe-border-color-secondary-50 {
  border: 1px solid #f4f7fa;
}
.fe-border-color-secondary-100 {
  border: 1px solid #e7ebf2;
}
.fe-border-color-secondary-200 {
  border: 1px solid #d4dce9;
}
.fe-border-color-secondary-300 {
  border: 1px solid #b6c5da;
}
.fe-border-color-secondary-400 {
  border: 1px solid #93a7c7;
}
.fe-border-color-secondary-500 {
  border: 1px solid #798db8;
}
.fe-border-color-secondary-600 {
  border: 1px solid #6b7bab;
}
.fe-border-color-secondary-700 {
  border: 1px solid #5b689a;
}
.fe-border-color-secondary-800 {
  border: 1px solid #4e577f;
}
.fe-border-color-secondary-900 {
  border: 1px solid #424966;
}
.fe-border-color-neutral-50 {
  border: 1px solid #fcfdfe;
}
.fe-border-color-neutral-100 {
  border: 1px solid #f1f5f9;
}
.fe-border-color-neutral-200 {
  border: 1px solid #e2e8f0;
}
.fe-border-color-neutral-300 {
  border: 1px solid #cbd5e1;
}
.fe-border-color-neutral-400 {
  border: 1px solid #94a3b8;
}
.fe-border-color-neutral-500 {
  border: 1px solid #64748b;
}
.fe-border-color-neutral-600 {
  border: 1px solid #475569;
}
.fe-border-color-neutral-700 {
  border: 1px solid #334155;
}
.fe-border-color-neutral-800 {
  border: 1px solid #1e293b;
}
.fe-border-color-neutral-900 {
  border: 1px solid #0f172a;
}

.fe-border-bold-color-new-base-black {
  border: 4px solid #000000;
}
.fe-border-bold-color-new-base-white {
  border: 4px solid #ffffff;
}
.fe-border-bold-color-new-grey-600 {
  border: 4px solid #222222;
}
.fe-border-bold-color-new-grey-500 {
  border: 4px solid #3e3d40;
}
.fe-border-bold-color-new-grey-400 {
  border: 4px solid #5e5e5e;
}
.fe-border-bold-color-new-grey-300 {
  border: 4px solid #98989a;
}
.fe-border-bold-color-new-grey-200 {
  border: 4px solid #ececed;
}
.fe-border-bold-color-new-grey-100 {
  border: 4px solid #f8f8f9;
}
.fe-border-bold-color-new-success-100 {
  border: 4px solid #eef7f0;
}
.fe-border-bold-color-new-success-200 {
  border: 4px solid #46905b;
}
.fe-border-bold-color-new-error-100 {
  border: 4px solid #ffeded;
}
.fe-border-bold-color-new-error-200 {
  border: 4px solid #b90608;
}
.fe-border-bold-color-new-warn {
  border: 4px solid #eebe5e;
}
.fe-border-bold-color-new-info {
  border: 4px solid #6177b9;
}
.fe-border-bold-color-primary-50 {
  border: 4px solid #f3f6fb;
}
.fe-border-bold-color-primary-100 {
  border: 4px solid #eff4ff;
}
.fe-border-bold-color-primary-200 {
  border: 4px solid #bed3ff;
}
.fe-border-bold-color-primary-300 {
  border: 4px solid #91b7ff;
}
.fe-border-bold-color-primary-400 {
  border: 4px solid #5d90fd;
}
.fe-border-bold-color-primary-500 {
  border: 4px solid #3264fa;
}
.fe-border-bold-color-primary-600 {
  border: 4px solid #3264fa;
}
.fe-border-bold-color-primary-700 {
  border: 4px solid #1933dc;
}
.fe-border-bold-color-primary-800 {
  border: 4px solid #1b2ab2;
}
.fe-border-bold-color-primary-900 {
  border: 4px solid #161c55;
}
.fe-border-bold-color-primary-950 {
  border: 4px solid #262f45;
}
.fe-border-bold-color-secondary-50 {
  border: 4px solid #f4f7fa;
}
.fe-border-bold-color-secondary-100 {
  border: 4px solid #e7ebf2;
}
.fe-border-bold-color-secondary-200 {
  border: 4px solid #d4dce9;
}
.fe-border-bold-color-secondary-300 {
  border: 4px solid #b6c5da;
}
.fe-border-bold-color-secondary-400 {
  border: 4px solid #93a7c7;
}
.fe-border-bold-color-secondary-500 {
  border: 4px solid #798db8;
}
.fe-border-bold-color-secondary-600 {
  border: 4px solid #6b7bab;
}
.fe-border-bold-color-secondary-700 {
  border: 4px solid #5b689a;
}
.fe-border-bold-color-secondary-800 {
  border: 4px solid #4e577f;
}
.fe-border-bold-color-secondary-900 {
  border: 4px solid #424966;
}
.fe-border-bold-color-neutral-50 {
  border: 4px solid #fcfdfe;
}
.fe-border-bold-color-neutral-100 {
  border: 4px solid #f1f5f9;
}
.fe-border-bold-color-neutral-200 {
  border: 4px solid #e2e8f0;
}
.fe-border-bold-color-neutral-300 {
  border: 4px solid #cbd5e1;
}
.fe-border-bold-color-neutral-400 {
  border: 4px solid #94a3b8;
}
.fe-border-bold-color-neutral-500 {
  border: 4px solid #64748b;
}
.fe-border-bold-color-neutral-600 {
  border: 4px solid #475569;
}
.fe-border-bold-color-neutral-700 {
  border: 4px solid #334155;
}
.fe-border-bold-color-neutral-800 {
  border: 4px solid #1e293b;
}
.fe-border-bold-color-neutral-900 {
  border: 4px solid #0f172a;
}

.fe-progress-color-new-base-black ::-webkit-progress-value {
  background-color: #000000 !important;
}
.fe-progress-color-new-base-white ::-webkit-progress-value {
  background-color: #ffffff !important;
}
.fe-progress-color-new-grey-600 ::-webkit-progress-value {
  background-color: #222222 !important;
}
.fe-progress-color-new-grey-500 ::-webkit-progress-value {
  background-color: #3e3d40 !important;
}
.fe-progress-color-new-grey-400 ::-webkit-progress-value {
  background-color: #5e5e5e !important;
}
.fe-progress-color-new-grey-300 ::-webkit-progress-value {
  background-color: #98989a !important;
}
.fe-progress-color-new-grey-200 ::-webkit-progress-value {
  background-color: #ececed !important;
}
.fe-progress-color-new-grey-100 ::-webkit-progress-value {
  background-color: #f8f8f9 !important;
}
.fe-progress-color-new-success-100 ::-webkit-progress-value {
  background-color: #eef7f0 !important;
}
.fe-progress-color-new-success-200 ::-webkit-progress-value {
  background-color: #46905b !important;
}
.fe-progress-color-new-error-100 ::-webkit-progress-value {
  background-color: #ffeded !important;
}
.fe-progress-color-new-error-200 ::-webkit-progress-value {
  background-color: #b90608 !important;
}
.fe-progress-color-new-warn ::-webkit-progress-value {
  background-color: #eebe5e !important;
}
.fe-progress-color-new-info ::-webkit-progress-value {
  background-color: #6177b9 !important;
}
.fe-progress-color-primary-50 ::-webkit-progress-value {
  background-color: #f3f6fb !important;
}
.fe-progress-color-primary-100 ::-webkit-progress-value {
  background-color: #eff4ff !important;
}
.fe-progress-color-primary-200 ::-webkit-progress-value {
  background-color: #bed3ff !important;
}
.fe-progress-color-primary-300 ::-webkit-progress-value {
  background-color: #91b7ff !important;
}
.fe-progress-color-primary-400 ::-webkit-progress-value {
  background-color: #5d90fd !important;
}
.fe-progress-color-primary-500 ::-webkit-progress-value {
  background-color: #3264fa !important;
}
.fe-progress-color-primary-600 ::-webkit-progress-value {
  background-color: #3264fa !important;
}
.fe-progress-color-primary-700 ::-webkit-progress-value {
  background-color: #1933dc !important;
}
.fe-progress-color-primary-800 ::-webkit-progress-value {
  background-color: #1b2ab2 !important;
}
.fe-progress-color-primary-900 ::-webkit-progress-value {
  background-color: #161c55 !important;
}
.fe-progress-color-primary-950 ::-webkit-progress-value {
  background-color: #262f45 !important;
}
.fe-progress-color-secondary-50 ::-webkit-progress-value {
  background-color: #f4f7fa !important;
}
.fe-progress-color-secondary-100 ::-webkit-progress-value {
  background-color: #e7ebf2 !important;
}
.fe-progress-color-secondary-200 ::-webkit-progress-value {
  background-color: #d4dce9 !important;
}
.fe-progress-color-secondary-300 ::-webkit-progress-value {
  background-color: #b6c5da !important;
}
.fe-progress-color-secondary-400 ::-webkit-progress-value {
  background-color: #93a7c7 !important;
}
.fe-progress-color-secondary-500 ::-webkit-progress-value {
  background-color: #798db8 !important;
}
.fe-progress-color-secondary-600 ::-webkit-progress-value {
  background-color: #6b7bab !important;
}
.fe-progress-color-secondary-700 ::-webkit-progress-value {
  background-color: #5b689a !important;
}
.fe-progress-color-secondary-800 ::-webkit-progress-value {
  background-color: #4e577f !important;
}
.fe-progress-color-secondary-900 ::-webkit-progress-value {
  background-color: #424966 !important;
}
.fe-progress-color-neutral-50 ::-webkit-progress-value {
  background-color: #fcfdfe !important;
}
.fe-progress-color-neutral-100 ::-webkit-progress-value {
  background-color: #f1f5f9 !important;
}
.fe-progress-color-neutral-200 ::-webkit-progress-value {
  background-color: #e2e8f0 !important;
}
.fe-progress-color-neutral-300 ::-webkit-progress-value {
  background-color: #cbd5e1 !important;
}
.fe-progress-color-neutral-400 ::-webkit-progress-value {
  background-color: #94a3b8 !important;
}
.fe-progress-color-neutral-500 ::-webkit-progress-value {
  background-color: #64748b !important;
}
.fe-progress-color-neutral-600 ::-webkit-progress-value {
  background-color: #475569 !important;
}
.fe-progress-color-neutral-700 ::-webkit-progress-value {
  background-color: #334155 !important;
}
.fe-progress-color-neutral-800 ::-webkit-progress-value {
  background-color: #1e293b !important;
}
.fe-progress-color-neutral-900 ::-webkit-progress-value {
  background-color: #0f172a !important;
}

/* Utilities
   ========================================================================== */
.fe-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}