/* You can add global styles to this file, and also import other style files */
@use './assets/sass/core/base/typography' as typography;
@use './app/commons/styles/main.scss';
@use '@angular/material' as mat;
@use './app/commons/styles/base/my-theme.scss';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import './assets/sass/style.scss';
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import './assets/sass/plugins.scss';
// @import "./assets/css/style.rtl.css";
@import './assets/sass/style.angular.scss';

@import '~@ng-select/ng-select/themes/default.theme.css';
@include mat.core();

$fe-primary: mat.define-palette(my-theme.$my-primary-color);
$fe-accent: mat.define-palette(my-theme.$my-accent-color);
$fe-warn: mat.define-palette(my-theme.$my-warn-color);

$fe-theme: mat.define-light-theme(
	(
		color: (
			primary: $fe-primary,
			accent: $fe-accent,
			warn: $fe-warn
		)
	)
);

@include mat.all-component-themes($fe-theme);

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: typography.$Franklin-400 !important;
}

/********************************Scroll*****************************************/
::-webkit-scrollbar-thumb {
	background: #cbd5e1 !important;
	padding: 0px 4px;
	border-radius: 5px;
}
::-webkit-scrollbar {
	height: 4px;
	width: 6px;
}

::-webkit-scrollbar-track {
	background: #f1f5f9 !important;
}

:root {
	--visibility: hidden;
}

@media (min-width: 575px) {
	.modal-message {
		--bs-modal-width: 400px;
		--bs-modal-min-width: 400px;
	}

	.modal-confirm {
		--bs-modal-width: 410px;
		--bs-modal-min-width: 410px;
	}
	.modal-documents {
		--bs-modal-width: 613px;
		--bs-modal-min-width: 410px;
	}
	.modal-updateProposal {
		--bs-modal-width: 900px;
		--bs-modal-min-width: 410px;
	}
	.modal-iframe {
		--bs-modal-width: 1100px;
		--bs-modal-min-width: 410px;
	}
	.modal-priceTarget {
		--bs-modal-width: 453px;
		--bs-modal-min-width: 410px;
	}
	.modal-addPerfile {
		--bs-modal-width: 613px;
		--bs-modal-min-width: 410px;
	}
	.modal-message-warning {
		--bs-modal-width: 440px;
		--bs-modal-min-width: 440px;
	}

	.modal-loader {
		--bs-modal-width: 435px;
		--bs-modal-min-width: 435px;
	}

	.modal-settings,
	.modal-message-simple {
		--bs-modal-width: 320px;
		--bs-modal-min-width: 320px;
	}

	.modal-filters {
		--bs-modal-width: 400px;
		--bs-modal-min-width: 400px;
	}

	.modal-settings,
	.modal-filters {
		--bs-modal-margin: 0;
		--bs-modal-border-radius: 0;
		.modal-dialog {
			--bs-modal-margin: 0;
			--bs-modal-border-radius: 0;
			margin-right: 0 !important;
			-webkit-transform: none !important;
			transform: none !important;
		}
	}
}

.modal-backdrop {
	--bs-backdrop-bg: #42578b;
	--bs-backdrop-opacity: 0.8;
}

// .modal-content {
// 	overflow: hidden;
// }

.card {
	--bs-card-box-shadow: none;
}


span.title {
	display: flex;
	font-size: 14.5px;
	align-items: center;
	justify-content: flex-start;
	gap: 0.75rem;
	color: #b0b0b0;
}
span.content {
	display: flex;
	color: #454545;
	align-items: center;
	justify-content: flex-start;
	font-size: 16px;
	font-style: normal;
}

.flags {
	width: 18px;
	height: 18px;
	border-radius: 50%;
}
//class recaptcha
.grecaptcha-badge {
    visibility: var(--visibility);
}
