//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.content {
		padding: 0;	
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.content {
		padding: get($content-spacing, tablet-and-mobile) 0;
	}
}
