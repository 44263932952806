/*
NEW COLORS
*/

// FE COLORS
// $primary-50: #f3f6fb;
// $primary-100: #e4ecf5;
// $primary-200: #cfdfee;
// $primary-300: #adc9e3;
// $primary-400: #86aed4;
// $primary-500: #6993c8;
// $primary-600: #567bba;
// $primary-700: #4c6bac;
// $primary-800: #42578b;
// $primary-900: #394a6f;
// $primary-950: #262f45;
$primary: #3264fa;
$primary-50: #f3f6fb;
$primary-100: #eff4ff;
$primary-200: #bed3ff;
$primary-300: #91b7ff;
$primary-400: #5d90fd;
$primary-500: #3264fa;
$primary-600: #3264fa;
$primary-700: #1933dc;
$primary-800: #1b2ab2;
$primary-900: #161c55;
$primary-950: #262f45;
$secondary-50: #f4f7fa;
$secondary-100: #e7ebf2;
$secondary-200: #d4dce9;
$secondary-300: #b6c5da;
$secondary-400: #93a7c7;
$secondary-500: #798db8;
$secondary-600: #6b7bab;
$secondary-700: #5b689a;
$secondary-800: #4e577f;
$secondary-900: #424966;

$letter: #454545;
$letter-tertiary: #b0b0b0 !default;
$letter-secondary: #6d6d6d !default;

$neutral-50: #fcfdfe;
$neutral-100: #f1f5f9;
$neutral-150:#F6F6F6;
$neutral-200: #e2e8f0;
$neutral-300: #cbd5e1;
$neutral-400: #94a3b8;
$neutral-500: #64748b;
$neutral-600: #475569;
$neutral-700: #334155;
$neutral-800: #1e293b;
$neutral-900: #0f172a;

$success-50: #eefff4;
$success-600: #00c051;

$warning-50: #fffbeb;
$warning-600: #f59e0b;

$error-50: #fef3f280;
$error-600: #f4483f;
$error-100:#FEF3F2;

$info-50: #fbf4f9;
$info-600: #9c63b0;

// Defaukt
$new-base-black: #000000 !default;
$new-base-white: #ffffff !default;

$new-grey-600: #222222 !default;
$new-grey-500: #3e3d40 !default;
$new-grey-400: #5e5e5e !default;
$new-grey-450: #8c8c8c !default;
$new-grey-300: #98989a !default;
$new-grey-200: #ececed !default;
$new-grey-100: #f8f8f9 !default;

$new-success-50: #eefff4;
$new-success-100: #eef7f0 !default;
$new-success-200: #46905b !default;
$new-success-500: #00c051 !default;

$new-error-50: #fef3f2;
$new-error-100: #ffeded !default;
$new-error-200: #b90608 !default;
$new-error-400: #f72419 !default;
$new-error-300: #f72d23 !default;
$new-error-500: #f4483f;

$new-warn: #eebe5e !default;
$new-warn-500: #fbbf24 !default;
$new-info: #6177b9 !default;

$new-primary-100: #e4ecf5;

$purple-50: #faf7fc;
$purple-500: #ac73c2;

$mulberry-500: #dc8abf;
$scooter-500: #00b5cc;
$esmeralda-500: #29c195;
$moderate:#D1D1D1;
$colors: () !default;
$colors: map-merge(
	(
		'new-base-black': $new-base-black,
		'new-base-white': $new-base-white,
		'new-grey-600': $new-grey-600,
		'new-grey-500': $new-grey-500,
		'new-grey-400': $new-grey-400,
		'new-grey-300': $new-grey-300,
		'new-grey-200': $new-grey-200,
		'new-grey-100': $new-grey-100,
		'new-success-100': $new-success-100,
		'new-success-200': $new-success-200,
		'new-error-100': $new-error-100,
		'new-error-200': $new-error-200,
		'new-warn': $new-warn,
		'new-info': $new-info,

		// FE COLORS
		'primary-50': $primary-50,
		'primary-100': $primary-100,
		'primary-200': $primary-200,
		'primary-300': $primary-300,
		'primary-400': $primary-400,
		'primary-500': $primary-500,
		'primary-600': $primary-600,
		'primary-700': $primary-700,
		'primary-800': $primary-800,
		'primary-900': $primary-900,
		'primary-950': $primary-950,
		'secondary-50': $secondary-50,
		'secondary-100': $secondary-100,
		'secondary-200': $secondary-200,
		'secondary-300': $secondary-300,
		'secondary-400': $secondary-400,
		'secondary-500': $secondary-500,
		'secondary-600': $secondary-600,
		'secondary-700': $secondary-700,
		'secondary-800': $secondary-800,
		'secondary-900': $secondary-900,
		'neutral-50': $neutral-50,
		'neutral-100': $neutral-100,
		'neutral-200': $neutral-200,
		'neutral-300': $neutral-300,
		'neutral-400': $neutral-400,
		'neutral-500': $neutral-500,
		'neutral-600': $neutral-600,
		'neutral-700': $neutral-700,
		'neutral-800': $neutral-800,
		'neutral-900': $neutral-900
	),
	$colors
);

@mixin color-modifiers {
	@each $name, $hex in $colors {
		&-#{'' + $name} {
			color: $hex;
		}
	}
}

// generates classes like fe-color-primary
.fe-color {
	@include color-modifiers;
}

//background colors
@mixin background-color-modifiers {
	@each $name, $hex in $colors {
		&-#{'' + $name} {
			background-color: $hex;
		}
	}
}

// generates classes like fe-background-color-primary
.fe-background-color {
	@include background-color-modifiers;
}

//background hover colors
@mixin background-hover-color-modifiers {
	@each $name, $hex in $colors {
		&-#{'' + $name}:hover {
			background-color: $hex;
		}
	}
}

// generates classes like fe-background-hover-color-primary
.fe-background-hover-color {
	@include background-hover-color-modifiers;
}

@mixin border-modifiers {
	@each $name, $hex in $colors {
		&-#{'' + $name} {
			border: 1px solid $hex;
		}
	}
}

// generates classes like fe-border-primary
.fe-border-color {
	@include border-modifiers;
}

@mixin border-bold-modifiers {
	@each $name, $hex in $colors {
		&-#{'' + $name} {
			border: 4px solid $hex;
		}
	}
}

// generates classes like fe--border-bold-primary
.fe-border-bold-color {
	@include border-bold-modifiers;
}

@mixin progress-modifiers {
	@each $name, $hex in $colors {
		&-#{'' + $name} {
			::-webkit-progress-value {
				background-color: $hex !important;
			}
			// border: 4px solid $hex;
		}
	}
}

// generates classes like fe-border-primary
.fe-progress-color {
	@include progress-modifiers;
}
