/* Utilities
   ========================================================================== */

%fe-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.fe-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin fe-truncate() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin fe-truncate-lines($size) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $size;
	overflow: hidden;
}

// Mixin

@mixin fe-flexbox-center() {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin fe-flexbox-start() {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@mixin fe-flexbox-end() {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@mixin fe-flexbox-space-between() {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin fe-flexbox-space-around() {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

@mixin fe-flexbox-param($display, $align, $justify) {
	display: $display;
	align-items: $align;
	justify-content: $justify;
}

@mixin fe-font-details($size, $line-h, $weight, $color) {
	font-size: $size;
	line-height: $line-h;
	font-weight: $weight;
	color: $color;
}
